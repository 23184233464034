import { useCookie } from 'nuxt/app';
import zhConverter from 'zh_cn_zh_tw';
import futurus from 'avris-futurus';
import { longtimeCookieSetting } from '../src/cookieSettings.ts';
import { escapeHtml } from '../src/helpers.ts';
import useConfig from './useConfig.ts';
import { useMainStore } from '../store/index.ts';

declare global {
    interface Window {
        toShavian?(text: string): string;
    }
}

export default () => {
    const config = useConfig();
    const store = useMainStore();
    const spellingCookie = useCookie('spelling', longtimeCookieSetting);

    return {
        handleSpelling(str: string): string {
            if (config.locale === 'zh' && store.spelling === 'simplified') {
                return zhConverter.convertToSimplifiedChinese(str);
            }

            if (config.locale === 'pl' && store.spelling === 'futurysci') {
                return futurus.futuriseText(str);
            }

            if (config.locale === 'en' && store.spelling === 'shavian') {
                if (typeof window === 'undefined' || !window.toShavian) {
                    this.setSpelling(''); // dependencies not loaded, disable
                    return str;
                }

                const shavian = str.replace(/(<[^>]*>)|([^<]+)/g, (match, htmlTag, text) => {
                    if (htmlTag) {
                        return htmlTag;
                    }

                    return window.toShavian!(text);
                });

                return `<span class="shavian">${shavian}</span>`;
            }

            if (config.locale === 'tok' && store.spelling === 'sitelen') {
                return `<span class="sitelen">${str}</span>`;
            }

            return str;
        },
        convertName(name: string): string {
            if (config.locale === 'tok') {
                const m = name.match(/^jan (.+?) \(((?:[mnptkswlj]?[iueoa][mn]? ?)+)\)$/i);
                if (!m) {
                    return escapeHtml(name);
                }

                if (store.spelling === 'sitelen') {
                    return `jan <span class="cartouche">${escapeHtml(m[2])}</span>`;
                }

                return `jan ${escapeHtml(m[1])}`;
            }

            return escapeHtml(name);
        },
        setSpelling(spelling: string): void {
            store.setSpelling(spelling);
            spellingCookie.value = store.spelling;
        },
    };
};
